import { ROLES } from '@/shared/constants';
import axios from 'axios';

const pathMap = {
  [ROLES.ROLE_ADMIN]: '/api/admin/balance_change',
  [ROLES.ROLE_SUPERVISOR]: '/api/supervisor/balance_change',
};

export default (role = ROLES.ROLE_AGENT) => {
  const CONTROLLER_BASE_PATH = pathMap[role];

  return {
    async createBalanceChange(payload) {
      const { data } = await axios.post(CONTROLLER_BASE_PATH, payload);

      return data;
    },
    async findBalanceChanges(payload) {
      const { data } = await axios.post(
        `${CONTROLLER_BASE_PATH}/find`,
        payload
      );

      return data;
    },
    async checkMerchantTransactionId(payload) {
      const { data } = await axios.post(
        `${CONTROLLER_BASE_PATH}/check_merchant_transaction_id`,
        payload
      );

      return data;
    },
  };
};
