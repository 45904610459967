export default function isEmpty(val) {
  if (Array.isArray(val)) {
    return !val.length;
  }

  if (typeof val === 'string' || typeof val === 'number') {
    return !val.toString().trim().length;
  }

  if (typeof val === 'object' && !!val) {
    return !Object.keys(val).length;
  }

  return !val;
}
